import { handleError } from "./errors";

export const get_roles = (user_details) => {
    try {
        let unique_roles = new Set();
        let current_bu_id = user_details.current_bu_id || (user_details.business_unit_ids && user_details.business_unit_ids.length && user_details.business_unit_ids[0])
        if (!current_bu_id) return ''
        for (let i in user_details.role.store) {
            unique_roles.add(user_details.role.store[i]);
        }
        for (let i in user_details.role.business_unit[current_bu_id]) {
            unique_roles.add(user_details.role.business_unit[current_bu_id][i]);
        }

        return Array.from(unique_roles);
    } catch (err) {
        console.error(`Error in get_roles `, err)
        handleError(err);
    }
}

export const user_has_access_to_portal = (user_details, portal) => {
    try{
        let user_roles = get_roles(user_details)
        let admin_roles = ["project_manager", "catalog_manager", "accounts_manager"]
        let design_roles = ["designer"]
        let mes_roles = ["mes_user", "mes_editor"]
        let cs_roles = ["customer_success_admin", "customer_success_executive"]
        switch (portal) {
            case "admin":
                return user_roles.find(role => admin_roles.includes(role));
            case "design":
                return user_roles.find(role => design_roles.includes(role));
            case "mes":
                return user_roles.find(role => mes_roles.includes(role));
            case "csp":
                return user_roles.find(role => cs_roles.includes(role));
            case "bdp":
                return user_roles.find(role => cs_roles.includes(role));
            case "sales":
                return user_roles.find(role => cs_roles.includes(role));
            default:
                break;
        }
    }catch(err){
        console.error(`Error in user_has_access_to_portal `, err)
        handleError(err);
    }
}